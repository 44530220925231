import React, { useEffect, useState } from "react";
import github from "../../github.png";

function Project(props) {
  return (
    <div className={"boxProject " + props.orientation}>
      <div className="boxProject1">
        <div className={"boxProject" + props.orientation}>
          <p className="titleProject">{props.title}</p>
          <p className="projectDescription">{props.description}</p>
          <div className={"linksProject" + props.orientation}>
            {props.link !== "" && (
              <a
                target="_blank"
                className={"linkProject" + props.orientation}
                href={props.link}
              >
                {props.id === "discord"
                  ? props.language === "english"
                    ? "Invite the bot"
                    : "Inviter le bot"
                  : props.id === "tracker"
                  ? props.language === "english"
                    ? "See live demo"
                    : "Voir une démo"
                  : props.link}
              </a>
            )}
            {props.github !== "" && (
              <a target="_blank" className="githubProject" href={props.github}>
                <img className="githubProjectImg" src={github}></img>
              </a>
            )}
          </div>
        </div>
        <img
          src={props.image}
          alt={props.id}
          onClick={() => {
            props.setIsOpen(true);
            props.setImage(props.image);
            props.setAltImage("");
          }}
          id={props.id === "jpma" ? "jpmaImg" : ""}
          className="screenshotProject"
        />
      </div>
      <div className={"linksProjectMobile"}>
        {props.link !== "" && (
          <a
            target="_blank"
            className={"linkProject" + props.orientation}
            href={props.link}
          >
            {props.id === "discord" ? "Invite the bot" : props.link}
          </a>
        )}
        {props.github !== "" && (
          <a target="_blank" className="githubProject" href={props.github}>
            <img className="githubProjectImg" src={github}></img>
          </a>
        )}
      </div>
      <div className={"technoList" + props.orientation}>
        {props.technologies.map((techno) => (
          <p className={"technoP " + techno.replace(".", "")} key={techno}>
            {techno}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Project;
