import React, { useEffect, useState } from 'react';
import './App.css';
import Portfolio from './components/Portfolio/Portfolio';
import NavBar from './components/NavBar/NavBar'
import { ClickAwayListener } from '@mui/material';
import cross from "./close.png"
import LanguagePopUp from './components/LanguagePopUp/LanguagePopUp';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState()
  const [altImage, setAltImage] = useState("")
  const [language, setLanguage] = useState("")
  const [languagePopUpOpen, setLanguagePopUpOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else if (!isOpen) {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen])
  useEffect(() => {
    console.log(localStorage.getItem("language"))
    if(!localStorage.getItem("language")){
      setLanguagePopUpOpen(true)
    }else{
      setLanguage(localStorage.getItem("language"))
    }
  },[])
  function popUpSetLanguage(language){
    setLanguagePopUpOpen(false)
    setLanguage(language)
    localStorage.setItem("language",language)
  }
  return (
    <div className="App">
      <NavBar language={language} popUpSetLanguage={popUpSetLanguage}></NavBar>
      <Portfolio language={language} setIsOpen={setIsOpen} setImage={setImage} setAltImage={setAltImage}></Portfolio>
      {isOpen && (
        <div className="overlay" >
          <img id='crossOverlay' src={cross}></img>
          <ClickAwayListener onClickAway={() => { setIsOpen(false) }} touchEvent={false}>
            <img src={image} alt={altImage} className="fullsize-image" />
          </ClickAwayListener>
        </div>
      )}
      <LanguagePopUp languagePopUpOpen={languagePopUpOpen} setLanguagePopUpOpen={setLanguagePopUpOpen} popUpSetLanguage={popUpSetLanguage}></LanguagePopUp>
      <LanguageSelector classCSS="languageSelectorDesktop" language={language} popUpSetLanguage={popUpSetLanguage}></LanguageSelector>

    </div>
  )

}

export default App;
