import React, { useState } from 'react';
import './LanguageSelector.css';


function LanguageSelector(props) {

    return (

        <div id='languageSelectorMobile' className={props.classCSS}>
            <button id={props.language === "french" ? "languageActive" : "languageInactive"} onClick={() => { props.popUpSetLanguage("french") }}>Français</button>
            <button id={props.language === "english" ? "languageActive" : "languageInactive"} onClick={() => { props.popUpSetLanguage("english") }}>English</button>
        </div>


    )

}

export default LanguageSelector;
