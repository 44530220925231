import React, { useEffect, useState } from 'react';
import './NavBar.css';
import nodeImage from "../../node.png"
import reactImage from "../../React.webp"
import pythonImage from "../../python-logo.png"
import solidityImage from "../../solidity.png"
import Tooltip from '@mui/material/Tooltip';
import copy from "../../clipboard.png"
import copied from "../../copied.png"
import hamburger from "../../hamburger.png"
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import texts from "./textsNavBar.json"


function NavBar(props) {
    const handleCopyClick = () => {
        navigator.clipboard.writeText("achille.dorier@protonmail.com");
        setCopyState(copied)
        setCopyText(texts[props.language][1])
        setTimeout(() => {
            setCopyState(copy)
            setCopyText(texts[props.language][0])
        }, 5000)
    };
    const [copyState, setCopyState] = useState(copy)
    const [copyText, setCopyText] = useState()
    const [menuMobile, setMenuMobile] = useState("linksBarMobile")
    useEffect(() => {if(props.language) setCopyText(texts[props.language][0]) }, [props.language])
    function toggleMenu() {
        console.log("toggling to " + menuMobile === "linksBarMobile" ? "linksBarMobileActive" : "linksBarMobile")
        setMenuMobile(menuMobile === "linksBarMobile" ? "linksBarMobileActive" : "linksBarMobile")
        document.body.style.overflow = menuMobile === "linksBarMobile" ? 'hidden' : ''
    }
    function handleClickAway() {
        console.log(menuMobile)
        if (menuMobile === "linksBarMobileActive") { setMenuMobile("linksBarMobile") }
    }
    return (
        <div id='navbar'>
            <div id='linksBar'>
                <a className='linkNavBar' href='https://www.linkedin.com/in/achille-dorier'>Linkedin</a>
                <a className='linkNavBar' href='https://github.com/achille1017'>Github</a>
                <a className='linkNavBar' href='https://www.fiverr.com/achilledev'>Fiverr</a>
                <Tooltip title={copyText}>
                    <button id='copyButton' onClick={handleCopyClick}>
                        <p className='linkNavBar'>Contact</p></button>
                </Tooltip>

            </div>

            <div className="logos">
                <div className="logos-slide">
                    <img src={nodeImage} />
                    <img src={reactImage} />
                    <img src={pythonImage} />
                    <img src={solidityImage} />

                </div>

                {<div className="logos-slide">
                    <img src={nodeImage} />
                    <img src={reactImage} />
                    <img src={pythonImage} />
                    <img src={solidityImage} />

                </div>}
                {<div className="logos-slide">
                    <img src={nodeImage} />
                    <img src={reactImage} />
                    <img src={pythonImage} />
                    <img src={solidityImage} />

                </div>}
            </div>

            <button onClick={toggleMenu} id='hamburger'><img src={hamburger} id='hamburgerImg'></img></button>
            {menuMobile === "linksBarMobileActive" &&
                <ClickAwayListener onClickAway={toggleMenu} touchEvent={false}>
                    <div id={menuMobile}>
                        <a className='linkNavBar' href='https://www.linkedin.com/in/achille-dorier'>Linkedin</a>
                        <a className='linkNavBar' href='https://github.com/achille1017'>Github</a>
                        <a className='linkNavBar' href='https://www.fiverr.com/achilledev'>Fiverr</a>
                        <Tooltip title={texts[props.language][1]} leaveDelay={4000} open={copyText === texts[props.language][1]}>

                            <button id='copyButton' onClick={handleCopyClick}>
                                <p className='linkNavBar'>Email</p>
                                <img id='copyImg' src={copyState}></img>
                            </button>
                        </Tooltip>
                        <LanguageSelector classCSS="" language={props.language} popUpSetLanguage={props.popUpSetLanguage}></LanguageSelector>
                    </div>
                </ClickAwayListener>}
            {menuMobile === "linksBarMobileActive" &&
                <div id='overlay'></div>}
        </div>
    )

}

export default NavBar;
