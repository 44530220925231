import React, { useState } from 'react';
import './LanguagePopUp.css';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

function LanguagePopUp(props) {
    return (
        <> {props.languagePopUpOpen &&

            <div id='overlayLanguagePopUp'>
                <ClickAwayListener onClickAway={() => props.popUpSetLanguage("english")} touchEvent={false}>

                    <div id='languagePopUp'>
                        <p id='chooseLanguageP'>Choose your language</p>
                        <div id='buttonsPopUpDiv'>
                            <button onClick={() => props.popUpSetLanguage("french")} className='buttonPopUp'>Français</button>
                            <button onClick={() => props.popUpSetLanguage("english")} className='buttonPopUp'>English</button>

                        </div>
                    </div>
                </ClickAwayListener>

            </div>
        }
        </>
    )

}

export default LanguagePopUp;
