import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import Zoom from "react-reveal/Zoom";
import moi from "../../moi.png";
import arrow from "../../arrowRight.png";
import jpma from "../../pf/jpma.jpg";
import Project from "../Project/Project";
import projects from "../../projects.json";
import walletLink from "../../pf/walletLinkTezos.png";
import id360 from "../../pf/id360.png";
import discord from "../../pf/discord.png";
import stinkat from "../../pf/stinkat.png";
import walletProvider from "../../pf/walletProvider1.png";
import ProjectHigh from "../ProjectHigh/ProjectHigh";
import tracker from "../../pf/screenTracker.png";
import bettingcroc from "../../pf/bettingcroc2.png";
import { PopupWidget } from "react-calendly";
import texts from "./textPortfolio.json";
import { PopupButton } from "react-calendly";
import verbacultura from "../../pf/verbacultura.png";

function Portfolio(props) {
  const [arrowClass, setArrowClass] = useState("");
  const handleScroll = () => {
    setArrowClass("none");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {texts && props.language && (
        <div id="portfolio">
          <div id="box1">
            <div id="box2Text">
              <div id="box1Text">
                <p className="whiteP" id="achilleP">
                  Achille Dorier
                </p>
                <p className="greyP box1P">
                  {texts[props.language]["firstScreen"][5]}
                </p>
                <p className="greyP box1P">
                  {texts[props.language]["firstScreen"][0]}
                </p>
                <div id="actionButtonsDiv">
                  <PopupButton
                    url="https://calendly.com/achille-dorier/introduction-30min"
                    className="actionButtonLanding"
                    rootElement={document.getElementById("root")}
                    text={texts[props.language]["firstScreen"][7]}
                  />
                  <a
                    className="actionButtonLanding"
                    href="mailto:achille.dorier@protonmail.com"
                  >
                    {texts[props.language]["firstScreen"][6]}
                  </a>
                </div>
              </div>
              <img id="arrow" className={arrowClass} src={arrow}></img>
            </div>
            <div id="box1About">
              <img id="moi" src={moi}></img>
              <p className="greyP" id="aboutP">
                {texts[props.language]["firstScreen"][1]}
              </p>
              <a
                id="resume"
                href={
                  props.language === "french"
                    ? "/achilledorierFR.pdf"
                    : "/achilledorierEN.pdf"
                }
              >
                {texts[props.language]["firstScreen"][2]}
              </a>
            </div>
          </div>
          <p id="someOfMy">{texts[props.language]["firstScreen"][3]}</p>
          <div id="projects">
            <Zoom>
              <ProjectHigh
                orientation={"Left"}
                image={verbacultura}
                title={projects[props.language].verbacultura.title}
                link={projects[props.language].verbacultura.link}
                github={projects[props.language].verbacultura.github}
                description={projects[props.language].verbacultura.description}
                technologies={
                  projects[props.language].verbacultura.technologies
                }
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></ProjectHigh>
            </Zoom>
            <Zoom>
              <Project
                orientation="Right"
                image={tracker}
                title={projects[props.language].tracker.title}
                link={projects[props.language].tracker.link}
                github={projects[props.language].tracker.github}
                description={projects[props.language].tracker.description}
                technologies={projects[props.language].tracker.technologies}
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
                id="tracker"
                language={props.language}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Left"}
                image={bettingcroc}
                title={projects[props.language].bettingcroc.title}
                link={projects[props.language].bettingcroc.link}
                github={projects[props.language].bettingcroc.github}
                description={projects[props.language].bettingcroc.description}
                technologies={projects[props.language].bettingcroc.technologies}
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Right"}
                image={stinkat}
                title={projects[props.language].stinkat.title}
                link={projects[props.language].stinkat.link}
                github={projects[props.language].stinkat.github}
                description={projects[props.language].stinkat.description}
                technologies={projects[props.language].stinkat.technologies}
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Left"}
                image={walletProvider}
                title={projects[props.language].walletProvider.title}
                link={projects[props.language].walletProvider.link}
                github={projects[props.language].walletProvider.github}
                description={
                  projects[props.language].walletProvider.description
                }
                technologies={
                  projects[props.language].walletProvider.technologies
                }
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Right"}
                image={jpma}
                title={projects[props.language].jpma.title}
                link={projects[props.language].jpma.link}
                github={projects[props.language].jpma.github}
                description={projects[props.language].jpma.description}
                technologies={projects[props.language].jpma.technologies}
                id="jpma"
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Left"}
                image={discord}
                title={projects[props.language].discordVerifier.title}
                link={projects[props.language].discordVerifier.link}
                github={projects[props.language].discordVerifier.github}
                description={
                  projects[props.language].discordVerifier.description
                }
                technologies={
                  projects[props.language].discordVerifier.technologies
                }
                id="discord"
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
                language={props.language}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Right"}
                image={id360}
                title={projects[props.language].id360.title}
                link={projects[props.language].id360.link}
                github={projects[props.language].id360.github}
                description={projects[props.language].id360.description}
                technologies={projects[props.language].id360.technologies}
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></Project>
            </Zoom>
            <Zoom>
              <Project
                orientation={"Left"}
                image={walletLink}
                title={projects[props.language].walletLink.title}
                link={projects[props.language].walletLink.link}
                github={projects[props.language].walletLink.github}
                description={projects[props.language].walletLink.description}
                technologies={projects[props.language].walletLink.technologies}
                setImage={props.setImage}
                setAltImage={props.setAltImage}
                setIsOpen={props.setIsOpen}
              ></Project>
            </Zoom>
          </div>
          <PopupWidget
            url="https://calendly.com/achille-dorier/introduction-30min"
            rootElement={document.getElementById("root")}
            text={texts[props.language]["firstScreen"][4]}
            textColor="#ffffff"
            color="#00a2ff"
          />
        </div>
      )}
    </>
  );
}

export default Portfolio;
